<template>
  <AppRow permission="DASHBOARD_ADMINISTRATION">
    <template v-slot>
      <ApolloQuery
        :query="require('../graphql/queries/getDashboardInfo.gql')"
        :variables="{
          dateFrom,
          dateTo,
          purseType: initPurseType()
        }" style="max-width: 100%"
      >
        <template v-slot="{ result: { error: dashboardError, data: dashboardData }, isLoading: dashboardIsLoading }">
          <!-- Error -->
          <AppNotification
            v-if="dashboardError"
            :message="dashboardError.message"
          />
          <template v-else-if="dashboardData || dashboardIsLoading === 1">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-select
                  v-model="purseType"
                  :items="selectPurseType"
                  background-color="#464646"
                  class="rounded-lg"
                  flat
                  hide-details="auto"
                  item-text="text"
                  item-value="key"
                  label="Игровая валюта"
                  solo
                  @change="refresh"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <DatePicker
                  label="Диапазон дат"
                  @save="saveDates"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- Result -->
                <v-row>
                  <v-col
                    v-for="item of cards"
                    :key="item.value"
                    :md="item.value === 'ngr' || item.value === 'houseEdge' ? '6' : '4'"
                    :sm="item.value === 'bonus_all' ? '12' : '6'"
                    cols="12"
                  >
                    <InfoCard
                      :data="dashboardData"
                      :currency="!item.isCurr ? '' : initPurseType()"
                      :item="item"
                      :loading="dashboardIsLoading"
                      property="getDashboardInfo"
                    >
                      <template v-if="item.value === 'withdraws' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showWithdraws = !showWithdraws"
                        >
                          <v-icon>{{ showWithdraws ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showWithdraws">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="withdraws_list_item of JSON.parse(dashboardData.getDashboardInfo['withdraws_list'])"
                                :key="withdraws_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ withdraws_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ withdraws_list_item.amount }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="pg_item of JSON.parse(dashboardData.getDashboardInfo['withdraws_payment_gateways'])"
                                :key="pg_item.title"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ pg_item.title }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ pg_item.value }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'bets' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBetsCount = !showBetsCount"
                        >
                          <v-icon>{{ showBetsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBetsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of dashboardData.getDashboardInfo.bets"
                                :key="bet_list_item.gameMode"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.gameMode }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ bet_list_item.amount.toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'bets_rtp' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBetsRTPCount = !showBetsRTPCount"
                        >
                          <v-icon>{{ showBetsRTPCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBetsRTPCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of dashboardData.getDashboardInfo.bets"
                                :key="bet_list_item.gameMode"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.gameMode }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ bet_list_item.rtp }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'withdraws_count' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showWithdrawsCount = !showWithdrawsCount"
                        >
                          <v-icon>{{ showWithdrawsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showWithdrawsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="withdraws_list_item of JSON.parse(dashboardData.getDashboardInfo['withdraws_list'])"
                                :key="withdraws_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ withdraws_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ withdraws_list_item.count }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'ftd_sum' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showFirstDepositsSum = !showFirstDepositsSum"
                        >
                          <v-icon>{{ showFirstDepositsSum ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showFirstDepositsSum">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['first_deposits_list'])"
                                :key="deposit_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.amount }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'ftd_count' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showFirstDepositsCount = !showFirstDepositsCount"
                        >
                          <v-icon>{{ showFirstDepositsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showFirstDepositsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['first_deposits_list'])"
                                :key="deposit_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.count }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'ftd_avg' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showFirstDepositsAvg = !showFirstDepositsAvg"
                        >
                          <v-icon>{{ showFirstDepositsAvg ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showFirstDepositsAvg">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['first_deposits_list'])"
                                :key="deposit_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.avg }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'deposits' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showDeposits = !showDeposits"
                        >
                          <v-icon>{{ showDeposits ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showDeposits">
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['deposits_list'])"
                                :key="deposit_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.amount }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="partner_item of JSON.parse(dashboardData.getDashboardInfo['partners_deposits'])"
                                :key="partner_item.title"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ partner_item.title }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ partner_item.value }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="pg_item of JSON.parse(dashboardData.getDashboardInfo['payment_gateways'])"
                                :key="pg_item.title"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ pg_item.title }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ pg_item.value }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'total_deposits_fee_applied' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showDeposits = !showDeposits"
                        >
                          <v-icon>{{ showDeposits ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showDeposits">
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['deposits_fee_applied'])"
                                :key="deposit_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.amount }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'reg_count' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showRegisters = !showRegisters"
                        >
                          <v-icon>{{ showRegisters ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showRegisters">
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="register_list_item of JSON.parse(dashboardData.getDashboardInfo['reg_list'])"
                                :key="register_list_item.currency"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ register_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ register_list_item.count }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                            <v-divider />
                            <v-card-text>
                              <v-list-item
                                v-for="register_partner of JSON.parse(dashboardData.getDashboardInfo['reg_partners'])"
                                :key="register_partner.title"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ register_partner.title }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ register_partner.value }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'deposits_count' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showDepositsCount = !showDepositsCount"
                        >
                          <v-icon>{{ showDepositsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showDepositsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['deposits_list'])"
                                :key="deposit_list_item.currency"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.count }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template v-if="item.value === 'check_avg' && dashboardData && dashboardData.getDashboardInfo">
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showCheckAvg = !showCheckAvg"
                        >
                          <v-icon>{{ showCheckAvg ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showCheckAvg">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="deposit_list_item of JSON.parse(dashboardData.getDashboardInfo['deposits_list'])"
                                :key="deposit_list_item.currency"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ deposit_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ deposit_list_item.avg }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'wins' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showWinsCount = !showWinsCount"
                        >
                          <v-icon>{{ showWinsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showWinsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['wins_list'])"
                                :key="bet_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'looses' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showWinsCount = !showWinsCount"
                        >
                          <v-icon>{{ showWinsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showWinsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['looses_list'])"
                                :key="bet_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'bets_wins' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBetsWinsCount = !showBetsWinsCount"
                        >
                          <v-icon>{{ showBetsWinsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBetsWinsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['bets_wins_list'])"
                                :key="bet_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'bets_looses' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBetsWinsCount = !showBetsWinsCount"
                        >
                          <v-icon>{{ showBetsWinsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBetsWinsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['bets_looses_list'])"
                                :key="bet_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'in_game' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBetsWinsCount = !showBetsWinsCount"
                        >
                          <v-icon>{{ showBetsWinsCount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBetsWinsCount">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['in_game_list'])"
                                :key="bet_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'balances' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBalances = !showBalances"
                        >
                          <v-icon>{{ showBalances ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBalances">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['balances_list'])"
                                :key="bet_list_item.currency"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                      <template
                        v-if="item.value === 'bonuses' && dashboardData && dashboardData.getDashboardInfo"
                      >
                        <v-btn
                          absolute
                          icon
                          right
                          top
                          @click="showBonuses = !showBonuses"
                        >
                          <v-icon>{{ showBonuses ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                        <v-expand-transition>
                          <div v-show="showBonuses">
                            <v-divider />

                            <v-card-text>
                              <v-list-item
                                v-for="bet_list_item of JSON.parse(dashboardData.getDashboardInfo['bonuses_list']).filter(item => +item.amount > 1e-10)"
                                :key="bet_list_item.currency + '_' + bet_list_item.label"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title>{{ bet_list_item.currency + ` (${bet_list_item.label})` }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ Number(bet_list_item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>
                          </div>
                        </v-expand-transition>
                      </template>
                    </InfoCard>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <ApolloQuery
                      :query="require('../graphql/queries/getUsersWriteOffBalanceSum.gql')"
                    >
                      <template v-slot="{ result: { error: writeOffError, data: writeOffData }, isLoading: writeOffIsLoading }">
                        <AppNotification
                          v-if="writeOffError"
                          :message="writeOffError.message"
                        />
                            <InfoCard
                              v-else-if="writeOffData || writeOffIsLoading === 1"
                              :currency="initPurseType()"
                              :item="itemWtiteOff"
                              :loading="writeOffIsLoading"
                              property="getUsersWriteOffBalanceSum"
                            >
                              <template
                                v-if="writeOffData && writeOffData.getUsersWriteOffBalanceSum"
                              >
                                <v-btn
                                  absolute
                                  icon
                                  right
                                  top
                                  @click="showWtiteOff = !showWtiteOff"
                                >
                                  <v-icon>{{ showWtiteOff ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                                <v-expand-transition>
                                  <div v-show="showWtiteOff">
                                    <v-divider />

                                    <v-card-text v-if="writeOffData.getUsersWriteOffBalanceSum.amounts && writeOffData.getUsersWriteOffBalanceSum.amounts.length">
                                      <v-list-item
                                        v-for="item of writeOffData.getUsersWriteOffBalanceSum.amounts"
                                        :key="item.currency"
                                        two-line
                                      >
                                      <v-row>
                                        <v-list-item-content>
                                          <v-row>
                                            <v-col>
                                              <v-list-item-title>{{ item.purseType }}</v-list-item-title>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-subtitle class="tar">{{ Number(item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-row>
                                      </v-list-item>
                                    </v-card-text>
                                    <v-card-text v-else>
                                      <p class="tac">Пока пусто :(</p>
                                    </v-card-text>
                                  </div>
                                </v-expand-transition>
                              </template>
                            </InfoCard>
                      </template>
                    </ApolloQuery>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <template v-if="false">
              <!-- // TODO temprorarily kicked
                <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <ApolloQuery
                  :query="require('../graphql/queries/getBannedUsersBalanceSum.gql')"
                  :variables="{
                    date: dateTo || dateFrom,
                    purseType: initPurseType()
                  }"
                >
                  <template v-slot="{ result: { error: bannedError, data: bannedData }, isLoading: bannedIsLoading }">
                    <AppNotification
                      v-if="bannedError"
                      :message="bannedError.message"
                    />
                        <InfoCard
                          v-else-if="bannedData || bannedIsLoading === 1"
                          :data="bannedData"
                          :currency="initPurseType()"
                          :item="itemBanned"
                          :loading="bannedIsLoading"
                          property="getBannedUsersBalanceSum"
                        >
                          <template
                            v-if="bannedData && bannedData.getBannedUsersBalanceSum"
                          >
                            <v-btn
                              absolute
                              icon
                              right
                              top
                              @click="showBanned = !showBanned"
                            >
                              <v-icon>{{ showBanned ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            <v-expand-transition>
                              <div v-show="showBanned">
                                <v-divider />

                                <v-card-text v-if="bannedData.getBannedUsersBalanceSum.amounts && bannedData.getBannedUsersBalanceSum.amounts.length">
                                  <v-list-item
                                    v-for="item of bannedData.getBannedUsersBalanceSum.amounts"
                                    :key="item.currency"
                                    two-line
                                  >
                                  <v-row>
                                      <v-list-item-content>
                                        <v-row>
                                        <v-col>
                                          <v-list-item-title>{{item.status}}</v-list-item-title>
                                        </v-col>
                                        <v-col>
                                          <v-list-item-title>{{item.purseType}}</v-list-item-title>
                                        </v-col>
                                        <v-col>
                                      <v-list-item-subtitle>{{ Number(item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-content>
                                  </v-row>
                                  </v-list-item>
                                </v-card-text>
                                <v-card-text v-else>
                                  <p class="tac">Пока пусто :(</p>
                                </v-card-text>
                              </div>
                            </v-expand-transition>
                          </template>
                        </InfoCard>
                  </template>
                </ApolloQuery>
              </v-col> -->
              </template>
              <template v-if="false">
              <!-- // TODO temprorarily kicked
                <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <ApolloQuery
                  :query="require('../graphql/queries/getFreezedUsersBalanceSum.gql')"
                  :variables="{
                    date: dateTo || dateFrom,
                    purseType: initPurseType()
                  }"
                >
                  <template v-slot="{ result: { error: feezedError, data: feezedData }, isLoading: feezedIsLoading }">
                    <AppNotification
                      v-if="feezedError"
                      :message="feezedError.message"
                    />
                        <InfoCard
                          v-else-if="feezedData || feezedIsLoading === 1"
                          :data="feezedData"
                          :currency="initPurseType()"
                          :item="itemFreezed"
                          :loading="feezedIsLoading"
                          property="getFreezedUsersBalanceSum"
                        >
                          <template
                            v-if="feezedData && feezedData.getFreezedUsersBalanceSum"
                          >
                            <v-btn
                              absolute
                              icon
                              right
                              top
                              @click="showFreezed = !showFreezed"
                            >
                              <v-icon>{{ showFreezed ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            <v-expand-transition>
                              <div v-show="showFreezed">
                                <v-divider />

                                <v-card-text v-if="feezedData.getFreezedUsersBalanceSum.amounts && feezedData.getFreezedUsersBalanceSum.amounts.length">
                                  <v-list-item
                                    v-for="item of feezedData.getFreezedUsersBalanceSum.amounts"
                                    :key="item.currency"
                                    two-line
                                  >
                                  <v-row>
                                      <v-list-item-content>
                                        <v-row>
                                        <v-col>
                                          <v-list-item-title>{{item.status}}</v-list-item-title>
                                        </v-col>
                                        <v-col>
                                          <v-list-item-title>{{item.purseType}}</v-list-item-title>
                                        </v-col>
                                        <v-col>
                                      <v-list-item-subtitle>{{ Number(item.amount || 0.0).toFixed(8) }}</v-list-item-subtitle>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-content>
                                  </v-row>
                                  </v-list-item>
                                </v-card-text>
                                <v-card-text v-else>
                                  <p class="tac">Пока пусто :(</p>
                                </v-card-text>
                              </div>
                            </v-expand-transition>
                          </template>
                        </InfoCard>
                  </template>
                </ApolloQuery>
              </v-col> -->
              </template>
            </v-row>
            <template v-if="false">
            <!-- // TODO temprorarily kicked
              <ApolloQuery
              :query="require('../graphql/queries/getUsersBalance.gql')"
              :variables="{
                date: dateTo || dateFrom,
                purseType: initPurseType()
              }"
              class="row"
            >
              <template v-slot="{ result: { error: ucountError, data: ucountData }, isLoading: ucountIsLoading }">
                <AppNotification
                  v-if="ucountError"
                  :message="ucountError.message"
                />
                <v-col
                  v-if="ucountData || ucountIsLoading === 1"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <InfoCard
                    :data="ucountData"
                    icon="mdi-account-multiple"
                    :currency="!activeUsers.isCurr ? '' : initPurseType()"
                    :item="activeUsers"
                    :loading="ucountIsLoading"
                    property="getUsersBalance"
                    sub="totalCount"
                  >
                    <template v-if="(ucountData && ucountData.getUsersBalance && ucountData.getUsersBalance.activeUsers)">
                      <v-btn
                        absolute
                        icon
                        right
                        top
                        @click="(showActive = !showActive)"
                      >
                        <v-icon>{{ showActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                      <v-expand-transition>
                        <div v-show="showActive">
                          <v-divider />

                          <v-card-text v-if="ucountData.getUsersBalance.activeUsers.amounts && ucountData.getUsersBalance.activeUsers.amounts.length">
                            <v-list-item
                              v-for="item of ucountData.getUsersBalance.activeUsers.amounts"
                              :key="item.currency"
                              two-line
                            >
                            <v-row>
                                <v-list-item-content>
                                  <v-row>
                                  <v-col>
                                    <v-list-item-title class="f">
                                      {{ item.purseType }}
                                      <v-list-item-subtitle>
                                        ( {{ item.payload }} )
                                      </v-list-item-subtitle>
                                    </v-list-item-title>
                                  </v-col>
                                  <v-col>
                                  </v-col>
                                  <v-col>
                                    <v-list-item-title class="tar">{{ Number(item.amount || 0.0).toFixed(8) }}</v-list-item-title>
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                            </v-row>
                            </v-list-item>
                          </v-card-text>
                          <v-card-text v-else>
                            <p class="tac">Пока пусто :(</p>
                          </v-card-text>
                        </div>
                      </v-expand-transition>
                    </template>
                  </InfoCard>
                </v-col>
                <v-col
                  v-if="ucountData || ucountIsLoading === 1"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <InfoCard
                    :data="ucountData"
                    icon="mdi-account-multiple-outline"
                    :currency="!inactiveUsers.isCurr ? '' : initPurseType()"
                    :item="inactiveUsers"
                    :loading="ucountIsLoading"
                    property="getUsersBalance"
                    sub="totalCount"
                  >
                    <template v-if="(ucountData && ucountData.getUsersBalance && ucountData.getUsersBalance.inactiveUsers)">
                      <v-btn
                        absolute
                        icon
                        right
                        top
                        @click="(showInactive = !showInactive)"
                      >
                        <v-icon>{{ showInactive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                      <v-expand-transition>
                        <div v-show="showInactive">
                          <v-divider />
                          <v-card-text v-if="ucountData.getUsersBalance.inactiveUsers.amounts && ucountData.getUsersBalance.inactiveUsers.amounts.length">
                            <v-list-item
                              v-for="item of ucountData.getUsersBalance.inactiveUsers.amounts"
                              :key="item.currency"
                              two-line
                            >
                            <v-row>
                                <v-list-item-content>
                                  <v-row>
                                  <v-col>
                                    <v-list-item-title class="f">
                                      {{item.purseType}}
                                      <v-list-item-subtitle>
                                        ( {{item.payload}} )
                                      </v-list-item-subtitle>
                                    </v-list-item-title>
                                  </v-col>
                                  <v-col>
                                    <v-list-item-title class="tar">{{ Number(item.amount || 0.0).toFixed(8) }}</v-list-item-title>
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                            </v-row>
                            </v-list-item>
                          </v-card-text>
                          <v-card-text v-else>
                            <p class="tac">Пока пусто :(</p>
                          </v-card-text>
                        </div>
                      </v-expand-transition>
                    </template>
                  </InfoCard>
                </v-col>
              </template>
            </ApolloQuery> -->
            </template>
            <v-row
              v-if="dashboardData && dashboardData.getDashboardInfo && dashboardData.getDashboardInfo['dashboard']"
              class="mt-4"
            >
              <LineBase
                :data="JSON.parse(dashboardData.getDashboardInfo['dashboard'])"
              />
            </v-row>
          </template>

          <!-- No result -->
          <AppNotification
            v-else
            message="Нет результата :("
          />
        </template>
      </ApolloQuery>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import DatePicker from '@/components/DatePicker.vue';
import AppNotification from '@/components/AppNotification.vue';
import InfoCard from '@/components/InfoCard.vue';
import LineBase from '@/components/LineBase.vue';

export default {
  components: {
    AppRow,
    DatePicker,
    AppNotification,
    InfoCard,
    LineBase,
  },
  data() {
    return {
      purseType: 6,
      gameModes: ['UNDEFINED_GAME_MODE', 'CRASH', 'DICE', 'MINES', 'DOUBLE', 'TOWER', 'JACKPOT', 'B2BSLOTS'],
      selectPurseType: [
        { text: 'INR', key: 0 },
        { text: 'BTC', key: 1 },
        { text: 'USD', key: 2 },
        { text: 'EUR', key: 3 },
        { text: 'ETC', key: 4 },
        { text: 'UAH', key: 5 },
        { text: 'RUB', key: 6 },
        { text: 'LTC', key: 7 },
        { text: 'DEMO', key: 8 },
        { text: 'ETH', key: 9 },
        { text: 'BRL', key: 10 },
        { text: 'BCH', key: 11 },
        { text: 'BNB', key: 12 },
        { text: 'DASH', key: 13 },
        { text: 'DOGE', key: 14 },
        { text: 'TRX', key: 15 },
        { text: 'USDT', key: 16 },
        { text: 'XMR', key: 17 },
        { text: 'ZEC', key: 18 },
        { text: 'XRP', key: 19 },
        { text: 'KZT', key: 20 },
        { text: 'VND', key: 21 },
        { text: 'UZS', key: 22 },
        { text: 'IDR', key: 23 },
        { text: 'AZN', key: 24 },
        { text: 'KGS', key: 25 },
        { text: 'PKR', key: 26 },
        { text: 'BDT', key: 27 },
        { text: 'CLP', key: 28 },
        { text: 'PEN', key: 29 },
        { text: 'COP', key: 30 },
        { text: 'MXN', key: 31 },
        { text: 'PYG', key: 32 },
        { text: 'UYU', key: 33 },
        { text: 'BOB', key: 34 },
        { text: 'CRC', key: 35 },
        { text: 'GTQ', key: 36 },
        { text: 'PAB', key: 37 },
        { text: 'NIO', key: 38 },
        { text: 'ARS', key: 39 },
        { text: 'SVC', key: 40 },
      ],
      dateFrom: '',
      dateTo: '',
      cards: [
        {
          text: 'Поступления (сумма)',
          value: 'deposits',
          isCurr: true,
        },
        {
          text: 'Поступления с учетом комиссий (сумма)',
          value: 'total_deposits_fee_applied',
          isCurr: true,
        },
        {
          text: 'Поступления (кол-во)',
          value: 'deposits_count',
          isCurr: false,
        },
        {
          text: 'Поступления (средняя сумма)',
          value: 'check_avg',
          isCurr: true,
        },
        {
          text: 'Первые депозиты (сумма)',
          value: 'ftd_sum',
          isCurr: true,
        },
        {
          text: 'Первые депозиты (кол-во)',
          value: 'ftd_count',
          isCurr: false,
        },
        {
          text: 'Первые депозиты (средняя сумма)',
          value: 'ftd_avg',
          isCurr: true,
        },
        {
          text: 'Регистрации (кол-во)',
          value: 'reg_count',
          isCurr: false,
        },
        {
          text: 'Выводы (кол-во)',
          value: 'withdraws_count',
          isCurr: false,
        },
        {
          text: 'Выводы (сумма)',
          value: 'withdraws',
          isCurr: true,
        },
        {
          text: 'Бонусы',
          value: 'bonuses',
          isCurr: true,
        },
        {
          text: 'Обороты (сумма)',
          value: 'bets',
          isCurr: false,
        },
        {
          text: 'RTP',
          value: 'bets_rtp',
          isCurr: false,
        },
        {
          text: 'Проиграно (сумма)',
          value: 'looses',
          isCurr: true,
        },
        {
          text: 'Выиграно (сумма)',
          value: 'wins',
          isCurr: true,
        },
        {
          text: 'Ставки проиграно (сумма)',
          value: 'bets_looses',
          isCurr: true,
        },
        {
          text: 'Ставки в игре (сумма)',
          value: 'in_game',
          isCurr: true,
        },
        {
          text: 'Ставки выиграно (сумма)',
          value: 'bets_wins',
          isCurr: true,
        },
        {
          text: 'Балансы (сумма)',
          value: 'balances',
          isCurr: true,
        },
      ],
      itemWtiteOff: {
        text: 'Cписанные балансы',
        value: 'amount',
        isCurr: true,
      },
      itemBanned: {
        text: 'Забаненные деньги',
        value: 'totalSum',
        isCurr: true,
      },
      itemFreezed: {
        text: 'Замороженные деньги',
        value: 'totalSum',
        isCurr: true,
      },
      activeUsers: {
        text: 'Активные',
        value: 'activeUsers',
        lastCount: 'totalSum',
        isCurr: true,
      },
      inactiveUsers: {
        text: 'Неактивные',
        value: 'inactiveUsers',
        lastCount: 'totalSum',
        isCurr: true,
      },
      showDeposits: false,
      showRegisters: false,
      showDepositsCount: false,
      showCheckAvg: false,
      showFirstDepositsSum: false,
      showFirstDepositsCount: false,
      showFirstDepositsAvg: false,
      showWithdraws: false,
      showWithdrawsCount: false,
      showBetsCount: false,
      showBetsRTPCount: false,
      showWinsCount: false,
      showBetsWinsCount: false,
      showBalances: false,
      showBonuses: false,
      showWtiteOff: false,
      showBanned: false,
      showFreezed: false,
      showActive: false,
      showInactive: false,
      expenses: [
        {
          text: 'Бонусное колесо',
          value: 'roll',
        },
        {
          text: 'Реферальная система',
          value: 'referrals',
        },
        {
          text: 'Промокоды',
          value: 'promotions',
        },
        {
          text: 'Комиссия платёжной системы',
          value: 'commissions',
        },
      ],
      headers: [
        { text: 'Режим', value: 'gameMode', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во ставок:', value: 'betsQty', class: 'white--text text-subtitle-2' },
        { text: 'Сумма ставок:', value: 'betsAmount', class: 'white--text text-subtitle-2' },
        { text: 'Выиграно:', value: 'winsAmount', class: 'white--text text-subtitle-2' },
        { text: 'Проиграно:', value: 'looseAmount', class: 'white--text text-subtitle-2' },
        { text: 'GGR:', value: 'ggr', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
    };
  },
  methods: {
    initPurseType() {
      const items = this.selectPurseType.filter(p => p.key === this.purseType);
      return items.length ? items[0].text : '';
    },
    saveDates(dates) {
      [this.dateFrom, this.dateTo] = dates;
    },
    refresh(value) {
      this.purseType = value;
    },
  },
};
</script>

<style>
.tac{
  text-align: center;
}
.tar{
  text-align: right;
}
.f{
    display: flex;
    align-items: center;
    gap: 4px;
  }
</style>
