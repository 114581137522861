<template>
  <div class="chart">
    <line-chart
      :data="data"
      area
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 400px;
  padding: 10px 5px;
  background: #1e1e1e;
  border-radius: 10px;
}

@media (max-width: 900px) {
  .chart {
    display: none;
  }
}
</style>
